<template>
  <div class="container flex center">
    <div v-if="error" class="notification is-danger">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <Icon name="icon-alert-triangle" />
          </div>

          <div class="level-item">
            <div>
              <p class="is-marginless">
                <strong>{{ $t('news.newsItemNotFoundTitle') }}</strong>
              </p>

              {{ $t('news.newsItemNotFoundMessage') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      error: false
    }
  },

  async created () {
    try {
      this.newsItem = await this.$store.dispatch('news/getById', this.$route.params.id)
      window.location = this.newsItem.link
    } catch (error) {
      this.error = true
    }
  }
}
</script>

<style scoped>
.container {
  padding: 1rem;
}
</style>
